<template>
  <div class="modal-md rounded-lg bg-white p-4 md:p-6">
    <div class="my-8 mt-4 text-center font-merri text-xl font-semibold">
      Choose your payment date...
    </div>
    <div class="space-y-4">
      <div class="px-2">
        For anyone who likes to manage their monthly outgoings on a set date, we
        hear you. All you need to do is choose the date you prefer below.
      </div>

      <div
        v-if="hasError"
        class="error-msg mb-4 rounded border-2 border-red-400 bg-red-100 p-4 py-3 text-red-400"
      >
        Oh no... there was an error. {{ errorMessage }}
      </div>
      <div class="flex items-center space-x-4">
        <div class="relative flex flex-grow items-center">
          <div
            class="pointer-events-none absolute left-0 p-3 pr-4 pt-3 text-lg text-gray-500"
          >
            <font-awesome-icon
              class="text-2xl"
              :icon="['fal', 'calendar-day']"
            />
          </div>
          <div class="pointer-events-none absolute right-0 mr-12 text-gray-600">
            of each month
          </div>
          <div
            class="pointer-events-none absolute right-0 p-3 pr-4 pt-3 text-lg text-gray-700"
          >
            <font-awesome-icon :icon="['far', 'chevron-down']" />
          </div>
          <select v-model="paymentDate" class="pl-12 text-base">
            <option v-for="index in 28" :key="`dom-${index}`" :value="index">
              {{ index }}{{ index | ordinal }}
            </option>
          </select>
        </div>
      </div>
      <div class="rounded bg-orange-100 p-3 px-4 text-center">
        Your next payment will be on
        <div class="py-2 text-xl font-semibold">
          {{ nextPayment | formatDate('do MMMM, yyyy') }}
        </div>
        <div class="text-sm italic">
          then on the {{ paymentDate }}{{ paymentDate | ordinal }} of each month
          thereafter
        </div>
      </div>
      <div class="">
        <form @submit.prevent="setPaymentDate" novalidate="true">
          <loading-button
            :is-loading="isLoading"
            :is-enabled="formValid"
            :is-full="true"
            class="mt-4"
            label="Confirm payment date"
          />
        </form>
      </div>
      <div class="mt-6 text-center text-gray-600">
        <a @click="cancelModal">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import { addMonths, setDate } from 'date-fns';

import LoadingButton from '@/components/LoadingButton';
import { mapGetters } from 'vuex';

export default {
  props: ['data', 'redirect'],
  components: {
    LoadingButton
  },

  computed: {
    formValid: function () {
      if (
        (this.pauseLength === '0' && !this.customDays) ||
        this.customError ||
        this.hitLimit
      ) {
        return false;
      }

      return true;
    },

    customError() {
      return (
        Number(this.pauseLength) === 0 && Number(this.customDays) > this.maxDays
      );
    },
    nextPayment() {
      const day = new Date().getDate();

      if (this.paymentDate <= day) {
        return addMonths(setDate(new Date(), this.paymentDate), 1);
      } else {
        return setDate(new Date(), this.paymentDate);
      }
    },

    ...mapGetters({
      subscription: 'getCurrentSubscription'
    })
  },
  data() {
    return {
      password: '',
      isLoading: false,
      hasError: false,
      errorMessage: '',
      paymentDate: 1
    };
  },
  methods: {
    closeModal: function (redirect) {
      if (this.data?.redirect) {
        this.$parent.$emit('make-close', redirect);
      }
      this.$parent.$emit('make-close');
    },
    cancelModal() {
      this.closeModal();
    },
    setPaymentDate() {
      this.isLoading = true;
      this.$store
        .dispatch('addPayday', { dom: this.paymentDate })
        .then(() => {
          this.$store.dispatch('sendMpEvent', {
            event: 'Change Payment Date - Success',
            properties: {
              location: this.$route.name,
              date: this.paymentDate,
              dateFormatted:
                this.paymentDate +
                  this.$options.filters.ordinal(this.paymentDate) ||
                this.paymentDate,
              type: 'EVT'
            }
          });

          this.$store
            .dispatch('getSubscription')
            .then(() => {
              this.isLoading = false;
              if (this.data?.redirect) {
                this.$parent.$emit('make-close', this.data?.redirect);
              } else {
                this.$parent.$emit('make-close');
              }
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.isLoading = false;

          this.$store.dispatch('sendMpEvent', {
            event: 'Change Payment Date - Error',
            properties: {
              location: this.$route.name,
              date: this.paymentDate,
              error: String(error.response.data.message),
              type: 'EVT'
            }
          });
        });
    }
  },
  mounted() {
    if (this.subscription?.payday) {
      this.paymentDate = new Date(this.subscription.nextbilldatefull).getDate();
    }
    this.$store.dispatch('sendMpEvent', {
      event: 'Change Payment Date',
      properties: {
        location: this.$route.name,
        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
